<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="'PO '+ Purchaseorder__title"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @hasDownloaded="hasDownloaded($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col class="pa-0 ma-0 mb-auto">
                            <v-row no-gutters>
                                <v-col class="col-9-5 d-flex flex-column pt-3">
                                    <div>
                                        <span class="header-title">{{ Office__title  }}</span>
                                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != null && Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(word => word != null && word != '').join(', ')  }}</span>
                                    <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                </v-col>
                                <v-col class="col-2-5 text-right">
                                    <img
                                        :src="appLogo"
                                        class="mb-3 mr-3"
                                        width="130px"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.purchaseOrder') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ Contract__sellertitle }}</span>
                                    <div class="d-flex flex-column">
                                        <span style="font-weight: normal">{{ Supplier__title }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address1 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address2 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address3 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__city + ' ' + (Supplier__postcode != null ? Supplier__postcode : '') }}</span>
                                        <span style="font-weight: normal">{{ Supplier__state }}</span>
                                        <span style="font-weight: normal">{{ Supplier__country }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0 offset-2">
                                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                                    <span>{{ Salesperson__name }}</span>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                                    <span>{{ Purchaseorder__podate ? formatDate(Purchaseorder__podate) : '' }}</span>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold text-right">{{ $t('message.number') }}</span>
                                    <span class="text-right">
                                    {{ Purchaseorder__title }}
                                </span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t(buyerCaption) }}</span>
                                    <span>{{ Office__title }}</span>
                                    <span>{{ Office__address1 }}</span>
                                    <span>{{ Office__address2 }}</span>
                                    <span>{{ Office__city + ' ' + Office__postcode}}</span>
                                    <span>{{ Office__state }}</span>
                                    <span>{{ Office__country }}</span>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column py-0" v-if="Contract__salestype_id != 5">
                                    <span class="font-weight-bold">{{ $t(deliveryCaption) }}</span>
                                    <div class="d-flex flex-column" v-if="Purchaseorder__notify_address_show == 1 && Deliveryaddress__address == null">
                                        <span>{{ Customer__title }}</span>
                                        <span>{{ Customer__address1 }}</span>
                                        <span>{{ Customer__address2 }}</span>
                                        <span>{{ Customer__address3 }}</span>
                                        <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '') }}</span>
                                        <span>{{ Customer__state }}</span>
                                        <span>{{ Customer__country }}</span>
                                        <span v-if="[3,4,5].includes(Customer__country_id)">{{ getTaxId() }}</span>
                                        <span v-if="[41].includes(Customer__country_id)" v-html="getTaxId()"></span>
                                    </div>
                                    <div class="d-flex flex-column" v-if="Purchaseorder__showcustomerasbuyer == 1">
                                        <span>{{ Customer__title }}</span>
                                        <span>{{ Customer__address1 }}</span>
                                        <span>{{ Customer__address2 }}</span>
                                        <span>{{ Customer__address3 }}</span>
                                        <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '') }}</span>
                                        <span>{{ Customer__state }}</span>
                                        <span>{{ Customer__country }}</span>
                                        <span v-html="getTaxId()"></span>
                                    </div>
                                    <div class="d-flex flex-column" v-if="Purchaseorder__notify_address_show == 1 && Purchaseorder__showcustomerasbuyer != 1 && Deliveryaddress__address != null">
                                        <span v-html="nl2br(Deliveryaddress__address)"></span>
                                    </div>
                                    <div v-else-if="Purchaseorder__notify_address_show != 1 && Purchaseorder__showcustomerasbuyer != 1">
                                        <span>{{ $t('message.toBeAdvised') }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column py-0" v-if="Contract__salestype_id == 5">
                                    <span class="font-weight-bold">{{ $t(deliveryCaption) }}</span>
                                    <div class="d-flex flex-column" v-if="Purchaseorder__notify_address_show == 1">
                                        <span>{{ Office__title }}</span>
                                        <span>{{ Office__address1 }}</span>
                                        <span>{{ Office__address2 }}</span>
                                        <span>{{ Office__city + ' ' + Office__postcode}}</span>
                                        <span>{{ Office__state }}</span>
                                        <span>{{ Office__country }}</span>
                                        <span v-html="getOfficeTaxId()"></span>
                                    </div>
                                    <div v-else>
                                        <span>{{ $t('message.toBeAdvised') }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="8" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                                    <span>{{ Paymentterm__title }}</span>
                                </v-col>
                                <v-col cols="4" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold text-right">{{ $t('message.shipment') }}</span>
                                    <span class="text-right">{{ Purchaseorder__shippingweekstatement }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="6" class="d-flex flex-column py-0 offset-6">
                                    <span class="font-weight-bold text-right">{{ $t('message.countryOriginDestination') }}</span>
                                    <span class="text-right" v-if="Loadingport__country == Origin__country && Origin__country != null">
                                        {{ Contract__productorigin_id != null ? (Origin__alt_country != null ? Origin__alt_country : Origin__country) : '' }} /
                                        {{ Contract__destinatione_id != null ? Destination__country : '' }}
                                    </span>
                                    <span class="text-right" v-else>
                                        {{ Loadingport__country }} /
                                        {{ Contract__destinatione_id != null ? Destination__country : '' }}
                                    </span>
                                    <span class="font-weight-bold text-right">{{ $t('message.termsOfDelivery') }}</span>
                                    <span class="text-right">{{ poIncoterm }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0 pb-0 pt-3 ma-0 mb-auto">
                            <v-simple-table
                                class="specification-table"
                                dense
                            >
                                <template v-slot:default>
                                    <tr class="table-header">
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.specification') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.size') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.grade') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1">{{ $t('message.quantity') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.unitPrice') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.amount') }}</td>
                                    </tr>
                                    <template v-for="(item,index) in Salesitems" v-if="item.type == 'default'">
                                        <tr class="table-row-main" v-if="index == 0 ">
                                            <td colspan="6">{{ item.Salesdescription.title + (Contract__destinatione_id == 4 && Contract__productgroup_id != null ? getHsCode(Contract__productgroup_id) : '') }}</td>
                                        </tr>
                                        <tr class="table-row" v-else-if="index > 0 && Salesitems[index - 1].Salesdescription.title != item.Salesdescription.title">
                                            <td colspan="6">{{ item.Salesdescription.title }}</td>
                                        </tr>
                                        <tr class="table-row-sub">
                                            <td></td>
                                            <td class="px-0">{{ item.Size.title }}</td>
                                            <td class="px-0">{{ item.Grade.title }}</td>
                                            <td class="px-0 text-right text-no-wrap">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.poqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</td>
                                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.costprice,'0,0.0000') : '' }}</td>
                                            <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber((item.Salesitem.costprice * item.Salesitem.poqty),currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) + ')' }}</td>
                                        </tr>
                                    </template>
                                    <template v-else-if="item.type == 'othercharge' && (item.Salesitem.chargeto == 1 || item.Salesitem.chargeto == 2)">
                                        <tr>
                                            <td></td>
                                            <td class="px-0">{{ item.Salesitem.size }}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="px-0 text-right">{{ formatThisNumber(item.Salesitem.poamount,currencyFormatPrecision) }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
                            <v-simple-table
                                class="specification-table"
                                dense
                            >
                                <template v-slot:default>
                                    <tr>
                                        <td colspan="4" class="px-0">
                                            <span style="white-space: pre-line">
                                                {{ Purchaseorder__shipremark != null ? Purchaseorder__shipremark.trim() : '' }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-0 font-weight-bold border-bottom-1"  style="vertical-align: bottom">
                                            <div class="d-flex flex-column">
                                                <span>{{ Contract__fsc != 0 && Contract__fsc != null ? $t('message.certCode') + ' : ' + certifications.find((certification) => certification.Certification.id == Contract__fsc).Certification.cert_no : '' }}</span>
                                                <span>{{ Contract__fsc != 0 && Contract__fsc != null ? $t('message.expiryDate') + ' : ' + certifications.find((certification) => certification.Certification.id == Contract__fsc).Certification.cert_expiry_date : '' }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold width-15-pct border-bottom-1 py-0" style="vertical-align: bottom">
                                            <div class="d-flex flex-row">
                                                <span class="text-uppercase pr-3">{{ $t('message.total') }}</span>
                                                <span v-if=" [1,2,3,5].includes(Contract__containersize_id) ">{{ Contract__containercount }}</span>
                                                <span v-if=" [1,2,3,5].includes(Contract__containersize_id) "> X </span>
                                                <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Contract__containersize_id).Containersize.title }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1" style="vertical-align: bottom">
                                            <span v-for="(total,index) in Salesitems__itemsTotalQty">
                                                    {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                            </span>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1" style="vertical-align: bottom">{{ currencies.find((currency) => currency.Currency.id == Purchaseorder__currency_id).Currency.code }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1" style="vertical-align: bottom">{{ formatThisNumber(Salesitems__itemsTotalAmount,currencyFormatPrecision) }}</td>
                                    </tr>
                                </template>
                            </v-simple-table>
                            <div class="html2pdf__page-break" v-if="Contract__longform == 1"></div>
                            <v-row no-gutters class="pt-2">
                                <v-col cols="12">{{ $t('message.notes') + ':' }}</v-col>
                                <v-col cols="12" v-for="footerNote in purchaseOrderFooterNotes" v-if="Purchaseorder__footer_notes.includes(footerNote.id)" :key="footerNote.id">
                                    <div class="d-flex flex-row align-stretch">
                                        <span class="px-1">-</span>
                                        <span class="footer-note text-wrap">{{ footerNote.text }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="align-end">
                                <v-col cols="5" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.confirmedBySeller') }}</span>
                                    <span class="stamp-area"></span>
                                    <span class="font-weight-bold border-top-1">{{ '(' + $t('message.pleaseSign') + ')' }}</span>
                                </v-col>
                                <v-col cols="7" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold ml-3">{{ $t('message.confirmedByBuyer') }}</span>
                                    <span class="stamp-area text-center pt-2">
                                        <img
                                            :src="(Contract__stampsignatured == 1 ? '/static/img/appic/stamps/' + Office__stamp_ws : '/static/img/appic/stamps/' + Office__stamp)"
                                            height="80px"
                                            v-if="Contract__showsignature != 'N'"
                                        >
                                    </span>
                                    <span class="font-weight-bold border-top-1 ml-3">{{ $t('message.companyStamp') }}</span>
                                </v-col>
                            </v-row>
                        </div>
                        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
	import AppConfig from "../../../constants/AppConfig";
	import {formatDate, isNumeric, numberFormat, nl2br} from "../../../helpers/helpers";
	import {api} from "../../../api";
	import {
		buyerCaptionTypes,
		certifications, containerSizes,
		currencies,
		incoterms,
		offices,
        productGroups,
		purchaseOrderFooterNotes,
		uofm
	} from "../../../store/modules/appic/constants";
    import VueHtml2pdf from 'vue-html2pdf';

	export default {
		name: "PrintPurchaseOrderHtml",
		props: ['purchaseOrderId','viewPdf'],
        components: {VueHtml2pdf},
		data() {
			return {
				appLogo: AppConfig.appSessionLogo,
				documentTitle: null,
				loaded: false,
				buyerCaptionTypes: buyerCaptionTypes,
				certifications: certifications,
				containerSizes: containerSizes,
				currencies: currencies,
				incoterms: incoterms,
				offices: offices,
                productGroups: productGroups,
				purchaseOrderFooterNotes: purchaseOrderFooterNotes,
				uofmQty: uofm.qty,
				Contract__buyeraddresstype: null,
				Contract__containercount: null,
				Contract__containersize_id: 1,
                Contract__currency_id: 2,
				Contract__destinatione_id: null,
                Contract__deliveryaddress_id: null,
				Contract__fsc: 0,
				Contract__id: null,
				Contract__incoterm_id: null,
				Contract__incotermport: null,
                Contract__longform: 0,
                Contract__office_id: 1,
				Contract__partial_no: null,
                Contract__productgroup_id: null,
				Contract__productorigin_id: null,
				Contract__revision_no: null,
                Contract__salestype_id: 1,
                Contract__sellertitle: null,
				Contract__shippingport_id: null,
				Contract__shippingweekstatement: null,
				Contract__showsignature: null,
				Contract__stampsignatured: null,
				Contract__title: null,
				Contract__version: null,
				Customer__title: '',
				Customer__address1: '',
				Customer__address2: '',
				Customer__address3: '',
				Customer__city: '',
				Customer__postcode: '',
				Customer__state: '',
				Customer__country: '',
                Customer__gstno: '',
                Customer__uscc: '',
				Deliveryaddress__address: null,
				Destination__country: null,
                Loadingport__country: null,
                Office__title: '',
				Office__address1: '',
				Office__address2: '',
				Office__city: '',
				Office__companyno: '',
				Office__contractemail: '',
				Office__contracttel: '',
				Office__country: '',
                Office__gstno: '',
                Office__id: 1,
				Office__postcode: '',
				Office__state: '',
                Office__stamp: 'stampappsdnbhd.png',
                Office__stamp_ws: 'stampsdnbhd.png',
				Office__website: '',
				Origin__country: null,
                Origin__alt_country: null,
                Paymentterm__title: null,
				Purchaseorder__currency_id: 2,
				Purchaseorder__footer_notes: [],
                Purchaseorder__incoterm_id: null,
                Purchaseorder__incotermport: null,
                Purchaseorder__notify_address_show: 0,
				Purchaseorder__podate: null,
				Purchaseorder__shippingweekstatement: null,
                Purchaseorder__showcustomerasbuyer: 0,
				Purchaseorder__shipremark: null,
                Purchaseorder__title: '',
				Salesperson__name: null,
				Salesitems: [],
				Salesitems__itemsTotalAmount: null,
				Salesitems__itemsTotalQty: [],
				Supplier__title: '',
				Supplier__address1: '',
				Supplier__address2: '',
				Supplier__address3: '',
				Supplier__city: '',
				Supplier__postcode: '',
				Supplier__state: '',
				Supplier__country: ''
			}
		},
		computed: {
			buyerCaption() {
				return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_first
			},
			poIncoterm() {
				let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Purchaseorder__incoterm_id)?.Incoterm?.title;
				let incotermport = '';
				switch(this.Purchaseorder__incotermport){
					case null:
						incotermport = ''
						break;
					case 0: //POD
						if(this.Contract__shippingport_id != null) {
							incotermport = ' ' + this.Shippingport__title
						}
						break;
					case 1: //POL
						if(this.Contract__loadingport_id != null) {
							incotermport = ' ' + this.Loadingport__title
						}
						break;
					case 2:
                        incotermport = ''
						break
				}
				return incoterm + incotermport;
			},
			currencyFormatPrecision () {
				return this.currencies.find((currency) => currency.Currency.id === this.Purchaseorder__currency_id)?.Currency?.formatPrecision
			},
			deliveryCaption() {
				return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_last
			}
		},
		methods: {
			formatDate,
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
            getHsCode (val) {
                let productGroup = this.productGroups.find(p => {
                    if(p.hasOwnProperty('header') == false && p.Productgroup.id == val){
                        return true
                    } else {
                        return  false
                    }
                })
                if(productGroup?.Productgroup.hscode){
                    return ", " + this.$t('message.hsCodeForImport') + ": " + productGroup?.Productgroup.hscode
                } else {
                    return ''
                }
            },
            getOfficeTaxId () {
                let taxId = ''
                if(this.Office__id == 21) taxId = 'NPWP No ' + nl2br(this.Office__gstno)
                if([20,22].includes(this.Office__id)) taxId = 'Tax ID ' + nl2br(this.Office__gstno)
                return taxId
            },
            getTaxId () {
                let taxId = ''
                if([4].includes(this.Customer__country_id) && this.Customer__gstno != null && this.Customer__gstno != '') taxId = 'NPWP No ' + nl2br(this.Customer__gstno)
                if([41].includes(this.Customer__country_id) && this.Customer__gstno != null && this.Customer__gstno != '') taxId = nl2br(this.Customer__gstno)
                if([5].includes(this.Customer__country_id) && this.Customer__uscc != null && this.Customer__uscc != '') taxId = 'USCC ' + this.Customer__uscc
                if([3].includes(this.Customer__country_id) && this.Customer__gstno != null && this.Customer__gstno != '') taxId = 'Tax ID ' + nl2br(this.Customer__gstno)
                if([2].includes(this.Customer__country_id) && this.Customer__gstno != null && this.Customer__gstno != '') taxId = 'Tax ID ' + nl2br(this.Customer__gstno,true)
                return taxId
            },
            hasDownloaded(blob) {
                this.$emit('pdf-has-downloaded')
            },
			loadPurchaseOrderById (purchasorder_id) {
				let self = this
				return new Promise((resolve, reject) => {
					api
						.get("/print/purchaseorders/" + purchasorder_id,{
						})
						.then(response => {
							for(let key in response.data.data[0]){
								self[key] = response.data.data[0][key]
							}
                            if(self['Purchaseorder__currency_id'] == null) self['Purchaseorder__currency_id'] = self['Contract__currency_id']
							resolve(purchasorder_id)
						})
						.catch(error => {
							reject(error);
						});
				})
			},
			loadSalesItemsByContract (purchasorder_id) {
				let self = this
				return new Promise((resolve, reject) => {
					api
						.get("/print/contracts/" + purchasorder_id + '/salesitems',{
						})
						.then(response => {
							self.Salesitems = response.data.data
							resolve(purchasorder_id)
						})
						.catch(error => {
							reject(error);
						});
				})
			},
            nl2br,
			async updateTotals () {
				let amount = 0, quantities = []
				await this.Salesitems.forEach((item) => {
					if(item.type == 'credit') {
                        amount -= isNumeric(item.Salesitem.poqty * item.Salesitem.costprice) ? parseFloat(item.Salesitem.poqty * item.Salesitem.costprice) : 0;
                    } else if(item.type == 'othercharge') {
					    if(item.Salesitem.chargeto == 1) {
                            amount += isNumeric(item.Salesitem.costprice) ? parseFloat(item.Salesitem.costprice) : 0;
                        }
					} else {
						amount += isNumeric(item.Salesitem.poqty * item.Salesitem.costprice) ? parseFloat(item.Salesitem.poqty * item.Salesitem.costprice) : 0;
					}

					let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
					if(idx !== -1){
						quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.poqty) ? parseFloat(item.Salesitem.poqty) : 0;
					} else {
						if(item.type == 'default') {
							let obj = {'Total': {}}
							obj['Total']['uofm'] = item.Measurement.title;
							obj['Total']['qty'] = isNumeric(item.Salesitem.poqty) ? parseFloat(item.Salesitem.poqty) : 0;
							quantities.push(obj)
						}
					}
				})
				this.Salesitems__itemsTotalAmount = amount
				this.Salesitems__itemsTotalQty = quantities
			},
			uofmPrecision (uofm) {
				return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
			},
		},
        watch: {
            viewPdf(val) {
                if(val) this.$refs.html2Pdf.generatePdf()
            },
        },
		created() {
			// if(this.$route.matched.some(({name}) => name === 'print_purchaseorder_html')){
				this.loadPurchaseOrderById(this.purchaseOrderId)
					.then(()=>{
						this.loadSalesItemsByContract(this.Contract__id)
							.then((response)=>{
								this.updateTotals().then(()=>{
									this.loaded = true
                                    // this.$refs.html2Pdf.generatePdf()
								})
							})
					})
			// }
		}
	}
</script>

<style>
	@font-face {
		font-family: 'AppicArial';
		src: url('/static/fonts/Arial.woff2') format('woff2'),
		url('/static/fonts/Arial.woff2') format('woff'),
		url('/static/fonts/Arial.woff2') format('truetype');
	}
	.border-top-1 {
		border-top: 1px solid black !important;
	}
	.border-bottom-1 {
		border-bottom: 1px solid black !important;
	}
	.border-top-2 {
		border-top: 2px solid black;
	}
	.border-bottom-2 {
		border-bottom: 2px solid black !important;
	}
	.col-2-5 {
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}
	.col-9-5 {
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}
	.document-caption {
		font-size: 16px !important;
		line-height: 18px;
	}
	.footer-note {
		font-size: 8pt !important;
	}
	.header-title {
		font-size: 20pt !important;
		line-height: 25px;
		font-weight: bold !important;
	}
	.header-subtitle {
		font-size: 10.5pt !important;
	}
    .paper-a4 {
        height: 1065px;
        margin-left: 75px;
        margin-top: 35px;
    }
	.specification-table table tr td {
		font-family:'AppicArial' !important;
		line-height:15px !important;
		font-size:9pt !important;
		padding:2px !important;
	}
	.specification-table table tr.table-header td {
		font-family:'AppicArial' !important;
		line-height:15px !important;
		font-size:9pt !important;
		padding:0px !important;
		height: 15px !important;
	}
	.specification-table table tr.table-row-main td {
		font-family:'AppicArial' !important;
		line-height:20px !important;
		font-size:9pt !important;
		padding:0px !important;
		height: 20px !important;
	}
	.specification-table table tr.table-row-sub td {
		font-family:'AppicArial' !important;
		line-height:15px !important;
		font-size:9pt !important;
		padding:0px !important;
		height: 15px !important;
	}
	.stamp-area {
		height: 100px;
	}
	.v-application{
		font-family:'AppicArial' !important;
		line-height:15px !important;
		font-size:9pt !important;
		padding:2px !important;
	}
	.v-application{
		font-family: 'AppicArial' !important;
		line-height:15px !important;
		font-size:9pt !important;
		padding:2px !important;
	}
	body {
		/*width: 18.5cm !important;*/
		margin: 0 !important;
	}
	* {
		text-rendering: geometricprecision !important;
	}
</style>